@import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

/* Application-wide Styles */
h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
}
h2, h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

* {
  font-family: Barlow, sans-serif !important;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.pi {
  font-family: "primeicons" !important;
}

body {
  --bkg-url: none;
  --bkg-header: transparent !important;
  --mat-color: black !important;
  --mat-button-color: #0060a8 !important;
  --mat-button-text-color: white !important;
  --mat-option-bkg: transparent !important;
  --mat-selected-option-bkg: transparent !important;
  --mat-border: 1px solid grey;
  --mat-border-opacity: 0;
  --mat-accordion-opacity: 1;
  --mat-accordion-border-color: #dee2e6;
  --loading-image: url('assets/images/loading.gif');
  --blue-background: transparent;
  --text-color: initial;
  --mat-icon-color: #0060a8;
  --mat-icon-button-background: #9cc3f7 !important;
  --authorship-bkg: #1c79a1 !important;
  --authorship-text-color: white !important;
  --authorship-url-color: #1c79a1 !important;
  --authorship-border: 2px solid #1c79a1 !important;
  --profile-background: #d3e4ff !important;
  --mat-form-field-border-color: initial !important;
  --mat-login-form-field-border-color: #d3e4fe !important;
  --label-color: initial !important;
  --pagination-buttons-border: 3px solid #d3e4fe !important;
  --pagination-buttons-bkg: #d3e4fe !important;
  --pagination-buttons-color: #1969ad !important;
  --ai-summary-color: #41484D !important;
  --ai-summary-header-color: #0060A8 !important;
  margin-left: 2em;
  margin-right: 2em;
  overflow: scroll;
  &.chat {
    overflow: hidden;
  }
}

body.dark-theme {
  --bkg-url: url("assets/images/galaxy.jpg");
  --bkg-header: rgba(0, 72, 128, .5) !important;
  --mat-color: #C4C7C7 !important;
  --mat-button-color: #a1c9ff !important;
  --mat-button-text-color: rgb(1, 72, 128) !important;
  --mat-option-bkg: rgb(1, 72, 128) !important;
  --mat-selected-option-bkg: rgb(0, 46, 81) !important;
  --mat-border: 2px solid rgb(1, 72, 128) !important;
  --mat-border-opacity: 1;
  --mat-accordion-opacity: .3;
  --mat-accordion-border-color: transparent;
  --loading-image: url('assets/images/loading2.gif');
  --blue-background: rgba(0, 28, 56, .7);
  --text-color: #C0C7CD !important;
  --mat-icon-color: #9cc3f7;
  --mat-icon-button-background: #0060a8 !important;
  --authorship-bkg: #1c79a1 !important;
  --authorship-text-color: #C0C7CD !important;
  --authorship-url-color: #C0C7CD !important;
  --authorship-border: 2px solid #1c79a1 !important;
  --profile-background: #014880 !important;
  --mat-form-field-border-color: #014880 !important;
  --mat-login-form-field-border-color: #005aa0 !important;
  --label-color: #A1C9FF !important;
  --pagination-buttons-border: 3px solid #014880 !important;
  --pagination-buttons-bkg: #014880 !important;
  --pagination-buttons-color: #c4c7c7 !important;
  --ai-summary-color: #C0C7CD !important;
  --ai-summary-header-color: #A1C9FF !important;
  background-position: center;
  overflow: scroll;
  &.chat {
    overflow: hidden;
  }
}

body, input[text], button {
  color: #888;
  font-family: Arial, Helvetica, sans-serif;
}

/* everywhere else */
* {
  font-family: Mulish, Arial, Helvetica, sans-serif;
}

dl, ol, ul, dd {
  margin: 0;
}

.clickable {
  cursor: pointer;
}

em {
  font-weight: bold;
}
html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  /* background-color: var(--bg-color); */
  background-image: var(--bkg-url);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.mat-select-value-text {
  color: var(--text-color);
}

.code-snippet {
  display: block;
  padding: 10px;
  background-color: #2d2d2d; 
  color: #f8f8f2;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  overflow-x: auto;
  border: 1px solid #444;
}

.question-link {
  color: var(--text-color);
  cursor: pointer;
}

code { color: blue; }
